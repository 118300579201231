import { type Keywords } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
interface KeywordType {
  data: Keywords[]
}

export const Keyword = ({ data }: KeywordType) => {
  return (
    <>
      {data && data.length > 0 ? (
        <section className='container mx-auto py-4 mb-2'>
          <div className='flex w-full flex-wrap'>
            <div className='mb-4 w-full text-base font-medium leading-tight text-dark-800'>
              Tìm kiếm nhiều nhất
            </div>
            <div className='flex w-full flex-wrap gap-2'>
              {data &&
                data.map((e, i) => {
                  return (
                    <Link
                      href={`/search-result?query=${encodeURIComponent(
                        getTranslate(e?.name)
                      )}`}
                      className='flex h-[30px] w-max cursor-pointer text-sm items-center rounded border bg-gray-1400 px-2'
                      key={i}
                    >
                      {getTranslate(e.name)}
                    </Link>
                  )
                })}
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  )
}

export default Keyword
