interface IconChatCloseProps {
  className?: string
}
export const IconChatClose = ({ className }: IconChatCloseProps) => {
  return (
    <svg
      className={className}
      width='50'
      height='50'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='30' cy='30' r='30' fill='#0065EE' />
      <path
        d='M40.3156 37.015C41.2281 37.928 41.2281 39.4031 40.3156 40.3161C39.8604 40.7713 39.2628 41 38.6648 41C38.0673 41 37.4698 40.7713 37.0145 40.3161L30 33.301L22.9855 40.3161C22.5302 40.7713 21.9327 41 21.3352 41C20.7372 41 20.1396 40.7713 19.6844 40.3161C18.7719 39.4031 18.7719 37.928 19.6844 37.015L26.6994 30.0003L19.6844 22.9856C18.7719 22.0725 18.7719 20.5974 19.6844 19.6844C20.5974 18.7719 22.0725 18.7719 22.9855 19.6844L30 26.6996L37.0145 19.6844C37.9275 18.7719 39.4026 18.7719 40.3156 19.6844C41.2281 20.5974 41.2281 22.0725 40.3156 22.9856L33.3006 30.0003L40.3156 37.015Z'
        fill='#FAFAFA'
      />
    </svg>
  )
}
