import { useAuth, useNotification } from '@/containers'
import { useClickOutsideComponent } from '@/utils/useClickOutsideComponent'
import Image from 'next/image'
import DrawChat from '../DrawChat'
import { IconChat } from '../Icon/IconChat'
import { IconChatClose } from '../Icon/IconChatClose'

export function PopupChat() {
  const { systemConfig } = useNotification()
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const { token, user } = useAuth()

  return (
    <>
      <DrawChat
        refer={ref || null}
        open={isComponentVisible}
        onClose={() => setIsComponentVisible(false)}
      >
        <div className='flex absolute flex-col gap-y-4 right-2'>
          <a
            href={systemConfig?.companyZalo}
            target='_blank'
            className='zalo flex items-center justify-end'
          >
            <span className='mr-2 font-semibold text-sm text-white'>Zalo</span>

            <Image
              src='assets/icons/social/zalo.png'
              width={44}
              height={44}
              alt='zalo'
              className='rounded-full overflow-hidden'
            />
          </a>
          <a
            href={systemConfig?.companyMessenger}
            target='_blank'
            className='messenger flex items-center justify-end '
          >
            <span className='mr-2 font-semibold text-sm text-white'>
              Messenger
            </span>
            <Image
              src='assets/icons/social/mess.svg'
              width={44}
              height={44}
              alt='mess'
              className='rounded-full overflow-hidden'
            />
          </a>

          <a
            href={`tel:${systemConfig?.companyHotline?.trim()}`}
            className='messenger flex items-center justify-end'
          >
            <p className='mr-2 font-semibold text-sm text-white flex flex-col text-right'>
              <span>Tổng đài</span>
              <span className='font-semibold text-sm text-white'>
                {systemConfig?.companyHotline}
              </span>
            </p>
            <Image
              src='assets/icons/social/hotline.svg'
              width={44}
              height={44}
              alt='hotline'
            />
          </a>
        </div>
      </DrawChat>
      <div
        onClick={() => setIsComponentVisible(true)}
        className={`block z-[999] fixed  sm:right-2 cursor-pointer ${
          user || token ? 'bottom-[120px]' : 'bottom-[140px]'
        }`}
      >
        {isComponentVisible ? <IconChatClose /> : <IconChat />}
      </div>
    </>
  )
}

export default PopupChat
