import ImageOptimize from '@/components/ImageOptimize'
import { PageTypeSuffix } from '@/constants'
import { useNotification, useStore } from '@/containers'
import { getTranslate } from '@/utils/api-interceptors'
import { Disclosure } from '@headlessui/react'
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid'

const Footer = () => {
  const { bottomMenu } = useStore()
  const { systemConfig } = useNotification()

  return (
    <footer id='footer' className={`mx-auto w-full`}>
      <section className={`bg-white mb-1`}>
        <div className={`container mx-auto flex justify-start flex-wrap`}>
          {bottomMenu &&
            bottomMenu.length > 0 &&
            bottomMenu.map((e, i) => (
              <Disclosure as={'div'} className={`w-full`} key={i}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='h-12 text-sm font-bold border-b border-b-white-500 text-dark-400 w-full text-left relative'>
                      {getTranslate(e.name)}
                      <svg
                        className={`${
                          open ? 'rotate-180' : 'rotate-[-90deg]'
                        } w-4 h-4 absolute right-0 top-4`}
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='#919499'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d={open ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
                        />
                      </svg>
                    </Disclosure.Button>

                    <Disclosure.Panel>
                      {e.children && e.children.length > 0 && (
                        <ul>
                          {e.children.map(f => (
                            <li
                              key={uuidv4()}
                              className='mb-[4px] h-12 flex items-center text-sm pl-3 border-b border-b-white-500 '
                            >
                              {f.type === 'link' ? (
                                <Link
                                  href={`${f.url}` || ''}
                                  className='block w-full'
                                >
                                  {getTranslate(f.name)}
                                </Link>
                              ) : (
                                <Link
                                  href={
                                    `/${f.slug + PageTypeSuffix.PAGE_CMS}` || ''
                                  }
                                >
                                  {getTranslate(f.name)}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          <div className='w-full py-3'>
            <div className='flex flex-wrap mb-3'>
              <p className='text-sm mb-2 font-bold text-dark-400 block'>
                {systemConfig?.companyPhoneTitle || 'Tư vấn mua hàng'}:
              </p>

              {systemConfig?.companyPhone && (
                <div className='w-full flex items-center leading-none gap-1'>
                  <p className='text-2xl font-semibold text-dark-900'>
                    {systemConfig?.companyPhone}
                  </p>
                  <p className='text-gray-800 italic text-sm'>
                    {systemConfig?.companyLabelPhone}
                  </p>
                </div>
              )}
            </div>
            <div className='flex flex-wrap mb-1'>
              <p className='text-sm mb-2 font-bold text-dark-400 block'>
                {systemConfig?.companyPhoneTitle2 || 'Bảo hành'}:
              </p>
              {systemConfig?.companyPhone2 && (
                <div className='w-full flex items-center leading-none gap-1'>
                  <p className='text-2xl font-semibold text-dark-900'>
                    {systemConfig?.companyPhone2}
                  </p>
                  <p className='text-gray-800 italic text-sm'>
                    {systemConfig?.companyLabelPhone2}
                  </p>
                </div>
              )}
            </div>
            <ul className='text-sm font-light text-gray-800'>
              {/* <li>
                <span>Website: </span>
                <span>{systemConfig?.companyWebsite}</span>
              </li> */}
              <li>
                <span>Email: </span>
                <span>{systemConfig?.companyEmail}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={`footerInfo bg-white pb-4 pt-3`}>
        <div className={`container flex flex-wrap`}>
          <div className='w-full'>
            <div className='grid grid-cols-2'>
              <ul className='mb-0 flex items-center'>
                <li className=''>
                  <Link
                    href={systemConfig?.companyWebsite || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-gray-400'
                  >
                    <img
                      src='/assets/icons/footer/web.svg'
                      alt='Facebook'
                      className='h-[18px] w-[18px]'
                    />
                  </Link>
                </li>
                <li className='ml-2'>
                  <Link
                    href={systemConfig?.companyFacebook || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-gray-400'
                  >
                    <img
                      src='/assets/icons/footer/fb.svg'
                      alt='Facebook'
                      className='h-[18px] w-[18px]'
                    />
                  </Link>
                </li>
                <li className='ml-2'>
                  <Link
                    href={systemConfig?.companyYoutube || '/'}
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-gray-400'
                    target='blank'
                  >
                    <img
                      src='/assets/icons/footer/youtube.svg'
                      alt='youtube'
                      className='h-[18px] w-[18px]'
                    />
                  </Link>
                </li>
                <li className='ml-2'>
                  <Link
                    href={systemConfig?.companyTikTok || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-gray-400'
                  >
                    <img
                      src='/assets/icons/footer/tiktok.svg'
                      alt='Tiktok'
                      className='h-[18px] w-[18px]'
                    />
                  </Link>
                </li>
              </ul>
              {systemConfig?.announcedMOIT && systemConfig?.companyVerify && (
                <Link
                  href={systemConfig?.companyVerify || ''}
                  title='Đã thông báo'
                  target='blank'
                  className='ml-auto mt-1 block'
                >
                  <ImageOptimize
                    width={160}
                    height={58}
                    src={systemConfig?.announcedMOIT || ''}
                    className='ml-auto h-auto'
                    alt='Đã thông báo'
                  />
                </Link>
              )}
            </div>
          </div>
          <div className='w-full mb-[100px] mt-6'>
            <ul className='text-base leading-tight text-center'>
              <li className='font-bold text-dark-400 mb-1'>
                <p>{getTranslate(systemConfig?.companyName)}</p>
              </li>
              <li className='italic text-gray-1200 text-xs'>
                <span>Giấy ĐKKD: </span>
                <span>{getTranslate(systemConfig?.businessLicense)}</span>
              </li>
              <li className='italic text-gray-1200 text-xs'>
                <span>Địa chỉ: </span>
                <span>{getTranslate(systemConfig?.companyAddress)}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </footer>
  )
}

export { Footer }
