import { type Menu } from '@/types'
import AccountTrigger from '../AccountTrigger'
import { MegaMenu } from '../MegaMenu/MegaMenu'
interface MenuProps {
  megaMenuData: Menu[]
  closeModal: () => void
}
export const MenuWrapper = ({ megaMenuData, closeModal }: MenuProps) => {
  return (
    <section className='menu-wrapper'>
      <div className='account-section'>
        <AccountTrigger closeModal={closeModal} />
      </div>
      <div className='megaMenu'>
        <MegaMenu megaMenuData={megaMenuData} closeModal={closeModal} />
      </div>
    </section>
  )
}
export default MenuWrapper
