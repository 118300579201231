import { useRouter } from 'next/router'
export const useRouterBack = () => {
  const router = useRouter()
  const handleGoBack = (event: boolean) => {
    if (router && event) {
      router.back()
      if (router?.pathname) {
        router.push('/')
      }
    }
  }
  return handleGoBack
}
