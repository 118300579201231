import { MenuWrapper } from '@/components/MenuWrapper'
import { PageType } from '@/constants'
import { useAuth, useStore } from '@/containers'
import {
  IconAccount,
  IconCart,
  IconCategory,
  IconCheckListOrder,
  IconClose,
  IconPromo,
} from '@/styles/Icon'
import { Dialog, Transition } from '@headlessui/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useMemo, useState } from 'react'
interface BottomNavigationType {
  layout: string
  page: string
}

const ComparePopup = dynamic(() => import('../components/Popup/ComparePopup'), {
  ssr: false,
})

const ChooseProductCompare = dynamic(
  () => import('../components/Popup/ChooseProductCompare'),
  {
    ssr: false,
  }
)

const BottomNavigation = ({ layout, page }: BottomNavigationType) => {
  const [active, setActive] = useState(1)
  const router = useRouter()
  const { token, cart } = useAuth()
  const [open, setOpen] = useState(false)
  // const { systemConfig } = useNotification()
  const {
    megaMenu,

    isOpenAddComparePopup,
    setProductCompares,
    productCompares,
    setIsOpenAddComparePopup,
    isOpenComparePopup,
    setIsOpenComparePopup,
    productViewed,
    catViewId,
  } = useStore()
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const handleActive = (item: number) => {
    setActive(item)
    if (item === 1) {
      if (router.pathname == '/') {
        scrollToTop()
      }
      router.push('/')
    } else if (item === 2) {
      setOpen(true)
    } else if (item === 3) {
      router.push('/hot-deal')
    } else if (item === 4) {
      router.push('/orders')
    } else if (item === 5) {
      router.push('/checkout/cart')
    } else if (item === 6) {
      if (token) {
        router.push('/account')
      } else {
        router.push('/login')
      }
    }
  }
  const closeModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (router.pathname == '/') {
      setActive(1)
    }
  }, [router.pathname])
  const footerHomePage = useMemo(() => {
    return (
      <>
        <div
          className={`fixed bottom-0 left-[50%] z-[999] grid grid-cols-5 h-[68px] w-full max-w-[440px] translate-x-[-50%] items-center justify-between bg-white shadow-100`}
          id='bottomMobileTab'
        >
          <div
            className={`flex flex-wrap items-center justify-center text-center text-[13px] gap-y-1`}
            onClick={() => handleActive(2)}
          >
            <span className='flex h-[28px] w-max items-center justify-center '>
              <IconCategory />
            </span>
            <p
              className={`w-full text-dark-500 ${active === 2 ? 'block' : ''}`}
            >
              Danh mục
            </p>
          </div>
          <div
            className={`flex flex-wrap items-center justify-center text-center text-[13px] gap-y-1`}
            onClick={() => handleActive(3)}
          >
            <span className='flex h-[28px] w-max items-center justify-center'>
              <IconPromo />
            </span>
            <p
              className={`w-full text-dark-500 ${active === 3 ? 'block' : ''}`}
            >
              Khuyến mãi
            </p>
          </div>

          <div
            className={`flex flex-wrap items-center justify-center text-center text-[13px] gap-y-1`}
            onClick={() => handleActive(4)}
          >
            <span className='flex h-[28px] w-max items-center justify-center'>
              <IconCheckListOrder />
            </span>
            <p
              className={`w-full text-dark-500 ${active === 3 ? 'block' : ''}`}
            >
              Tra cứu đơn
            </p>
          </div>

          <div
            className={`relative flex flex-wrap items-center justify-center text-center text-[13px] gap-y-1`}
            onClick={() => handleActive(5)}
          >
            <div className='relative flex h-[28px] w-max items-center justify-center'>
              <IconCart />
              {cart && cart.quantity != 0 && (
                <div
                  className={
                    'absolute right-[-8px] top-[-5px] z-10 block h-[18px] w-[18px] rounded-full bg-red text-[10px] font-normal leading-[18px] text-white'
                  }
                >
                  {cart && cart.quantity && cart.quantity > 9
                    ? '9+'
                    : cart.quantity}
                </div>
              )}
            </div>
            <p
              className={`w-full text-dark-500 ${active === 4 ? 'block' : ''}`}
            >
              Giỏ hàng
            </p>
          </div>
          <div
            className={`flex flex-wrap items-center justify-center text-center text-[13px] gap-y-1`}
            onClick={() => handleActive(6)}
          >
            <span className='flex h-[28px] w-max items-center justify-center'>
              <IconAccount />
            </span>
            <p
              className={`w-full text-dark-500 ${active === 5 ? 'block' : ''}`}
            >
              {token ? 'Trang của tôi' : 'Đăng nhập'}
            </p>
          </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as='div' className='relative  z-[9999]' onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 left-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 left-[50%] translate-x-[-50%] flex max-w-[440px]'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500 sm:duration-700'
                  enterFrom='translate-x-[-100%] opacity-0'
                  enterTo='translate-x-[0%] opacity-100'
                  leave='transform transition ease-in-out duration-500 sm:duration-700'
                  leaveFrom='translate-x-[0%] opacity-100'
                  leaveTo='translate-x-[-100%] opacity-0'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                    <div className='flex h-full flex-col bg-white relative'>
                      <span
                        className='absolute block right-4 z-10 top-9'
                        onClick={closeModal}
                      >
                        <IconClose />
                      </span>
                      <MenuWrapper
                        megaMenuData={megaMenu}
                        closeModal={closeModal}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }, [token, cart, open, megaMenu])

  return (
    <section>
      {layout &&
        (layout == PageType.HOMEPAGE || layout == PageType.CATEGORY) &&
        footerHomePage}
      {page === 'product-detail' && (
        <>
          <ComparePopup
            open={isOpenComparePopup}
            setIsOpenComparePopup={setIsOpenComparePopup}
            setProductCompares={setProductCompares}
            setIsOpenAddComparePopup={setIsOpenAddComparePopup}
            productCompares={productCompares}
          />
          <ChooseProductCompare
            catViewId={catViewId}
            productViewed={productViewed}
            isOpen={isOpenAddComparePopup}
            setIsOpenComparePopup={setIsOpenComparePopup}
            setProductCompares={setProductCompares}
            productCompares={productCompares}
            setIsOpen={setIsOpenAddComparePopup}
          />
        </>
      )}
    </section>
  )
}

export default BottomNavigation
