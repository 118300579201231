/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
export interface DrawChatProps {
  open: boolean
  children: JSX.Element[] | JSX.Element
  titleClassName?: string
  bodyClassName?: string
  onClose: () => void
  scroll?: boolean
  refer: any | null
}

export function DrawChat(props: DrawChatProps) {
  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        initialFocus={props.refer || null}
        as='div'
        static
        className={`relative z-[9999]`}
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 left-0 bottom-0 bg-black-400/50 transition-opacity' />
        </Transition.Child>
        <div className='pointer-events-none z-50 w-full overflow-hidden fixed left-[50%] translate-x-[-50%] rounded-tr-sm rounded-tl-sm bottom-[15px] flex max-h-[50vh] h-full max-w-[440px] '>
          <Transition.Child
            as={Fragment}
            enter='transform transition ease-in-out duration-500'
            enterFrom='translate-y-[30%]'
            enterTo='translate-y-[0%]'
            leave='transform transition ease-in-out duration-500'
            leaveFrom='translate-y-[30%]'
            leaveTo='translate-y-[100%]'
          >
            <Dialog.Panel className='pointer-events-auto w-full max-w-[440px] h-full shadow-modal'>
              {props.children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default DrawChat
