interface IconChatProps {
  className?: string
}
export const IconChat = ({ className }: IconChatProps) => {
  return (
    <svg
      width={54}
      height={54}
      className={className}
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx={30} cy={30} r={30} fill='#0099B0' fillOpacity='0.7' />
      <g clipPath='url(#clip0_3964_2882)'>
        <path
          d='M40.8213 45.0443C41.1386 45.0443 41.4516 45.165 41.6897 45.3949C43.2119 46.8649 45.4324 47.3543 47.4063 46.7417C46.1359 44.0952 45.9599 41.0632 46.9444 38.2559C48.1661 34.763 48.4723 31.2621 47.0818 27.751C45.8603 24.6665 43.503 22.1039 40.5196 20.6426C40.7032 21.6211 40.7957 22.618 40.7957 23.6256C40.7958 27.9021 39.1513 31.9243 36.1654 34.9513C33.1793 37.9787 29.1763 39.681 24.8941 39.7447C24.0985 39.7571 23.3065 39.7103 22.5234 39.6071C24.8769 43.7323 29.3154 46.419 34.1928 46.4914C36.3265 46.5244 38.3751 46.0773 40.283 45.1663C40.4548 45.0841 40.6388 45.0443 40.8213 45.0443Z'
          fill='white'
        />
        <path
          d='M24.9297 10.0027C17.3332 9.85188 11.0184 15.9974 11 23.5922C10.9956 25.4478 11.3578 27.2469 12.0767 28.9398C12.0871 28.9644 12.0969 28.9893 12.1056 29.0146C13.0901 31.8219 12.9141 34.8538 11.6437 37.5004C13.6178 38.1127 15.838 37.6236 17.3603 36.1536C17.7353 35.7913 18.2965 35.7 18.767 35.925C20.6746 36.8361 22.7235 37.2844 24.8571 37.2502C32.3942 37.1383 38.2982 31.1537 38.2981 23.6258C38.2982 16.2621 32.3011 10.1509 24.9297 10.0027ZM17.3685 25.6608C16.2771 25.6608 15.3923 24.7774 15.3923 23.6879C15.3923 22.5983 16.2771 21.715 17.3685 21.715C18.4599 21.715 19.3447 22.5983 19.3447 23.6879C19.3446 24.7774 18.4599 25.6608 17.3685 25.6608ZM24.6491 25.6608C23.5577 25.6608 22.6729 24.7774 22.6729 23.6879C22.6729 22.5983 23.5577 21.715 24.6491 21.715C25.7405 21.715 26.6253 22.5983 26.6253 23.6879C26.6253 24.7774 25.7405 25.6608 24.6491 25.6608ZM31.9298 25.6608C30.8384 25.6608 29.9536 24.7774 29.9536 23.6879C29.9536 22.5983 30.8384 21.715 31.9298 21.715C33.0212 21.715 33.906 22.5983 33.906 23.6879C33.906 24.7774 33.0212 25.6608 31.9298 25.6608Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3964_2882'>
          <rect
            width={37}
            height={37}
            fill='white'
            transform='translate(11 10)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
