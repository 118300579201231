import { useNotification } from '@/containers'
import { useAuth } from '@/containers/AuthContainer'
import Link from 'next/link'
import { useRouter } from 'next/router'
import ImageOptimize from '../ImageOptimize'
interface AccountTriggerProps {
  closeModal: () => void
}
const AccountTrigger = ({ closeModal }: AccountTriggerProps) => {
  const { systemConfig } = useNotification()
  const { token, user, logout } = useAuth()
  const router = useRouter()
  const handleLogOut = () => {
    logout()
    router.push('/')
    closeModal()
  }

  return (
    <div className='flex w-full px-2 pt-8 pb-5'>
      <div className='avt bg-[#51358D2B] rounded-full overflow-hidden p-[4px] max-w-[74px] max-h-[74px] flex items-center justify-center mb-2'>
        {user?.avatarImage ? (
          <ImageOptimize
            src={user?.avatarImage}
            alt={user?.name}
            width={200}
            height={200}
            loading='lazy'
            className='aspect-square object-cover rounded-full'
          />
        ) : (
          <ImageOptimize
            loading='lazy'
            className='h-full'
            alt={systemConfig?.websiteBrandName || ''}
            src={systemConfig?.companyLogoButton || ''}
            width={110}
            height={66}
          />
        )}
      </div>

      <div className='flex-1 ml-3 relative'>
        {token ? (
          <>
            <Link
              href={'/account'}
              className='text-headingMedium text-black block'
            >
              {user?.name}
            </Link>
            <Link href={'/account'} className='text-base text-black block'>
              {/* Thành viên {systemConfig?.websiteBrandName || ''} */}
              Thành viên Bạc
            </Link>
            <div
              onClick={() => handleLogOut()}
              className='text-purple cursor-pointer text-mainBody outline-none'
            >
              Đăng xuất
            </div>
          </>
        ) : (
          <>
            <ImageOptimize
              loading='lazy'
              className='h-[30px] w-auto'
              src={systemConfig?.companyLogoMainMobile || ''}
              alt={systemConfig?.websiteBrandName || ''}
              width={110}
              height={30}
            />
            <p className='text-fieldInput font-bold text-black'>
              Sắm ngay không cần đợi
            </p>
            <Link
              href='/login'
              className='text-purple text-mainBody outline-none'
            >
              Đăng nhập
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default AccountTrigger
