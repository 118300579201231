import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { EventBus, bus, useEventBus } from '../../hooks/useEventBus'

const OnDevelopment = () => {
  const isShowModal = useEventBus(EventBus.DEVELOPMENT_MODAL)
  if (!isShowModal) return <></>
  return (
    <Transition appear show={isShowModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-[9999]'
        onClose={() => bus.emit(EventBus.DEVELOPMENT_MODAL, false)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed z-[9999] inset-0 left-0 bottom-0 bg-dark-900/30 transition-opacity' />
        </Transition.Child>
        <div className='fixed z-[9999] inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-500'
              enterFrom='opacity-0 scale-50'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-500'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-50'
            >
              <Dialog.Panel className='w-full max-w-[380px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <div
                  className={
                    'modalContent flex justify-center flex-wrap px-2 pt-4'
                  }
                >
                  <div className={'header mb-5'}>
                    <svg
                      width={98}
                      height={34}
                      viewBox='0 0 98 34'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M90.5557 34H62.7192V19.2865H90.5557C94.6187 19.2865 97.9124 22.5803 97.9124 26.6433C97.9124 30.7063 94.6187 34 90.5557 34Z'
                        fill='#C4E2FF'
                      />
                      <path
                        d='M62.7199 34H7.44465C3.38166 34 0.0878906 30.7062 0.0878906 26.6432C0.0878906 22.5802 3.38166 19.2864 7.44465 19.2864H62.7199C66.7829 19.2864 70.0767 22.5802 70.0767 26.6432C70.0767 30.7062 66.7829 34 62.7199 34Z'
                        fill='#6CF5C2'
                      />
                      <path
                        d='M62.7199 34H7.44465C3.38166 34 0.0878906 30.7062 0.0878906 26.6432C0.0878906 22.5802 3.38166 19.2864 7.44465 19.2864H62.7199C66.7829 19.2864 70.0767 22.5802 70.0767 26.6432C70.0767 30.7062 66.7829 34 62.7199 34Z'
                        fill='#6CF5C2'
                      />
                      <path
                        d='M62.7196 19.2865H56.3569C60.4199 19.2865 63.7137 22.5803 63.7137 26.6433C63.7137 30.7063 60.4199 34 56.3569 34H62.7196C66.7826 34 70.0763 30.7063 70.0763 26.6433C70.0763 22.5803 66.7826 19.2865 62.7196 19.2865Z'
                        fill='#00F2A6'
                      />
                      <path
                        d='M23.6022 0.0783081H20.7406C19.9492 0.0783081 19.3076 0.719904 19.3076 1.51129V12.0915C19.3076 12.8829 19.9492 13.5245 20.7406 13.5245C21.532 13.5245 22.1736 12.8829 22.1736 12.0915V8.92079C22.709 8.91812 23.2684 8.91582 23.6022 8.91582C26.0228 8.91582 27.9921 6.93353 27.9921 4.49707C27.9921 2.06061 26.0228 0.0783081 23.6022 0.0783081ZM23.6022 6.04985C23.268 6.04985 22.7095 6.05214 22.1736 6.05482V2.94428H23.6022C24.4283 2.94428 25.1261 3.65542 25.1261 4.49707C25.1261 5.33871 24.4282 6.04985 23.6022 6.04985Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M72.6813 10.7369H69.72V8.23432H72.3572C73.1486 8.23432 73.7902 7.59273 73.7902 6.80134C73.7902 6.00995 73.1486 5.36835 72.3572 5.36835H69.72V2.86597H72.6813C73.4727 2.86597 74.1143 2.22438 74.1143 1.43299C74.1143 0.641596 73.4727 0 72.6813 0H68.287C67.4956 0 66.854 0.641596 66.854 1.43299V12.1699C66.854 12.9613 67.4956 13.6029 68.287 13.6029H72.6813C73.4727 13.6029 74.1143 12.9613 74.1143 12.1699C74.1143 11.3785 73.4727 10.7369 72.6813 10.7369Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M14.776 0.0783081C13.9846 0.0783081 13.343 0.719904 13.343 1.51129V8.78399C13.343 9.25917 13.206 9.87363 12.1372 10.4119C11.8076 10.5779 11.4486 10.6587 11.0401 10.6587C11.0388 10.6587 11.0372 10.6587 11.0361 10.6587C10.5907 10.6581 10.2046 10.5494 9.85569 10.3265C9.02417 9.79511 8.85718 9.34534 8.85718 8.79182V1.51149C8.85718 0.720096 8.21559 0.0785005 7.4242 0.0785005C6.63281 0.0785005 5.99121 0.720096 5.99121 1.51149V8.79182C5.99121 10.4285 6.77209 11.7573 8.31265 12.7415C9.1241 13.2599 10.0391 13.5234 11.0323 13.5247H11.0403C11.8951 13.5247 12.698 13.3386 13.4265 12.9716C15.7263 11.8135 16.209 10.0551 16.209 8.78399V1.51129C16.209 0.719904 15.5676 0.0783081 14.776 0.0783081Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M35.5694 0H32.5238C31.7324 0 31.0908 0.641596 31.0908 1.43299V12.1632C31.0908 12.951 31.7265 13.5908 32.5143 13.5962C32.5756 13.5966 33.5462 13.6031 34.4534 13.6031C34.927 13.6031 35.3833 13.6013 35.6836 13.596C38.964 13.5387 41.3451 10.6847 41.3451 6.80994C41.3451 2.73662 39.024 0 35.5694 0ZM37.6262 9.70916C37.1178 10.3725 36.4473 10.7163 35.6334 10.7304C35.2503 10.7373 34.5781 10.7378 33.9568 10.7363V2.86597H35.5694C38.2693 2.86597 38.4791 5.88461 38.4791 6.80994C38.4791 7.96168 38.1763 8.99113 37.6262 9.70916Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M50.6155 1.0778C50.6106 1.06461 50.6052 1.05124 50.5999 1.03825C50.3402 0.407351 49.7319 0 49.0498 0C49.0492 0 49.0484 0 49.0479 0C48.365 0.00076426 47.7566 0.409836 47.4981 1.04169C47.4937 1.05258 47.4895 1.06328 47.4853 1.07417L43.4537 11.6597C43.1718 12.3993 43.5431 13.2272 44.2827 13.5089C45.0227 13.7905 45.8502 13.4193 46.1318 12.6798L46.7845 10.9664H51.2841L51.9291 12.6756C52.1458 13.2494 52.6909 13.6031 53.2702 13.6031C53.4381 13.6031 53.6093 13.5732 53.7758 13.5104C54.5161 13.2311 54.89 12.4041 54.6105 11.6637L50.6155 1.0778ZM47.8761 8.10058L49.0446 5.03246L50.2025 8.10058H47.8761Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M62.2932 0H56.3637C55.5723 0 54.9307 0.641596 54.9307 1.43299C54.9307 2.22438 55.5723 2.86597 56.3637 2.86597H57.8834V12.1699C57.8834 12.9613 58.525 13.6029 59.3164 13.6029C60.1078 13.6029 60.7494 12.9613 60.7494 12.1699V2.86597H62.2932C63.0846 2.86597 63.7262 2.22438 63.7262 1.43299C63.7262 0.641596 63.0846 0 62.2932 0Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M78.6464 13.6029C79.4378 13.6029 80.0794 12.9613 80.0794 12.1699C80.0794 11.3784 79.4378 10.7369 78.6464 10.7369C77.8549 10.7369 77.2134 11.3784 77.2134 12.1699C77.2134 12.9613 77.8549 13.6029 78.6464 13.6029Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M84.6112 13.6029C85.4026 13.6029 86.0442 12.9613 86.0442 12.1699C86.0442 11.3784 85.4026 10.7369 84.6112 10.7369C83.8198 10.7369 83.1782 11.3784 83.1782 12.1699C83.1782 12.9613 83.8198 13.6029 84.6112 13.6029Z'
                        fill='#7A6D79'
                      />
                      <path
                        d='M90.5761 13.6029C91.3675 13.6029 92.009 12.9613 92.009 12.1699C92.009 11.3784 91.3675 10.7369 90.5761 10.7369C89.7846 10.7369 89.1431 11.3784 89.1431 12.1699C89.1431 12.9613 89.7846 13.6029 90.5761 13.6029Z'
                        fill='#7A6D79'
                      />
                    </svg>
                  </div>
                  <div
                    className={'desc text-center mb-5 text-dark-800 text-sm'}
                  >
                    Tính năng này đang được phát triển và sẽ được ra mắt trong
                    thời gian tới
                  </div>
                  <div
                    className={
                      'closeModal border rounded-full border-dark-900 text-dark-900 flex items-center justify-center h-8 min-w-[124px] px-5 text-sm cursor-pointer'
                    }
                    onClick={() => bus.emit(EventBus.DEVELOPMENT_MODAL, false)}
                  >
                    Đóng
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OnDevelopment
