import { MenuItemType, PageTypeSuffix } from '@/constants'
import type { SubMenu } from '@/types'
import { type Menu } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import { Tab } from '@headlessui/react'
import Link from 'next/link'
import { useState } from 'react'
import ImageOptimize from '../ImageOptimize'
interface MegaMenuProps {
  placeholder?: string
  className?: string
  isError?: boolean | string
  id?: string
  name?: string
  children?: JSX.Element[] | JSX.Element
  alert?: boolean
  value?: string
  icon?: JSX.Element
  megaMenuData: Menu[]
  closeModal: () => void
}

export const MegaMenu = ({ megaMenuData, closeModal }: MegaMenuProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const checkUrlType = (item: Menu | SubMenu) => {
    switch (item.type) {
      case MenuItemType.PRODUCT_CAT:
        return '/' + item.slug + PageTypeSuffix.CATEGORY
      case MenuItemType.POST_CAT:
        return '/' + item.slug + PageTypeSuffix.CATEGORY
      case MenuItemType.POST:
        return '/' + item.slug + PageTypeSuffix.POST
      case MenuItemType.PAGE_CMS:
        return '/' + item.slug + PageTypeSuffix.PAGE_CMS
      case MenuItemType.PRODUCT:
        return '/' + item.slug
      case MenuItemType.LINK:
        return item.url
      default:
        return '/'
    }
  }
  return (
    <>
      <Tab.Group
        vertical
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        as={'div'}
        className={'flex justify-between'}
      >
        <Tab.List
          className={
            'max-w-[112px] flex-[112px] gap-1 max-h-[calc(100svh-135px)] hiddenScrollBar overflow-y-auto pb-8'
          }
        >
          {megaMenuData
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((e, i) => {
              return (
                <Tab
                  key={i}
                  as={'div'}
                  className={
                    'tabItemMega ui-selected:border-l-[6px] ui-selected:font-bold ui-selected:border-primary-base ui-selected:bg-primary-base rounded-br-[4px] rounded-tr-[4px] bg-white-500 text-center text-mainBody outline-0 mb-1 last:mb-0'
                  }
                >
                  <div className='relative flex flex-col justify-center p-2'>
                    <div className='ui-selected:block absolute right-0 top-[50%] z-10 hidden h-0 w-0 translate-y-[-50%] border-b-[10px] border-r-[12px] border-t-[10px] border-b-transparent border-r-white border-t-transparent'></div>
                    <span className='icon h-[70px] w-[70px] px-1 aspect-square m-auto'>
                      <ImageOptimize
                        src={e.icon}
                        alt={getTranslate(e.name)}
                        width={140}
                        height={140}
                        thumbnail
                        loading='lazy'
                      />
                    </span>
                    <span className='title text-xs leading-4'>
                      {getTranslate(e.name)}
                    </span>
                  </div>
                </Tab>
              )
            })}
        </Tab.List>
        <Tab.Panels
          className={
            'flex-1 px-2 max-h-[calc(100vh-135px)] hiddenScrollBar overflow-y-auto pb-8'
          }
        >
          {megaMenuData
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((e, i) => {
              return (
                <Tab.Panel key={i}>
                  <div className='flex flex-col flex-wrap'>
                    <div className='header mb-2 flex w-full items-center justify-between'>
                      <h6 className='title text-fieldLabel font-bold'>
                        {getTranslate(e.name)}
                      </h6>
                      <a
                        href={checkUrlType(e)}
                        className='text-mainBody text-purple'
                      >
                        Tất cả
                      </a>
                    </div>
                    <div className='banner w-full'>
                      <ImageOptimize
                        src={e.image}
                        alt={getTranslate(e.name)}
                        width={1060}
                        height={360}
                        loading='lazy'
                        className='object-contain w-full'
                      />
                    </div>
                    <div className='main grid grid-cols-3 gap-3 p-3 px-2'>
                      {e.children
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((child, index) => {
                          return (
                            <Link
                              key={index}
                              onClick={closeModal}
                              href={checkUrlType(child) || '/'}
                              className={'outline-0'}
                            >
                              <div className='flex flex-col justify-center'>
                                <span className='icon px-2'>
                                  <ImageOptimize
                                    src={child.icon || '/'}
                                    alt={getTranslate(child.name)}
                                    width={120}
                                    height={120}
                                    thumbnail
                                    loading='lazy'
                                  />
                                </span>
                                <span className='text-dark line-clamp-2 h-[28px] text-center block overflow-hidden text-ellipsis text-textSmall text-dark-400'>
                                  {getTranslate(child.name)}
                                </span>
                              </div>
                            </Link>
                          )
                        })}
                    </div>
                  </div>
                </Tab.Panel>
              )
            })}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}
